#root {
  background-color: #282c34;
  color: white;
  font-family: "ProximaNova Regular";
  font-size: calc(10px + 1vmin);
  display: flex;
  flex-flow: column;
  min-height: 100%;
}

code {
  font-size: calc(1.5vmin);
}

a {
  color: lightskyblue;
  text-decoration: none;
}

nav {
  padding: 1vmin 2vmin;
  flex: 0 1 auto;
}

main {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
}

.VirtualSocial {
  text-align: center;
}

.Home {
  text-align: center;
}

.logo {
  height: 40vmin;
  pointer-events: all;
  cursor: pointer;
}

.Poster {
  padding: 50px;
}

.posterImg {
  max-width: 100%;
  pointer-events: none;
}

.Home {
  font-size: calc(10px + 2vmin);
}

.Home p {
  font-family: "ProximaNova Bold";
}

tr:nth-child(even) {
  background-color: #11111150;
}

.rblogo-container {
  position: fixed;
  top: 0;
  right: 0;
  margin: 0.5vmin 1.5vmin;
}

.rblogo-img {
  height: calc(3vmin);
}
