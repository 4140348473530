@font-face {
  font-family: "ProximaNova Regular";
  src: local("ProximaNova Regular"),
    url(./fonts/ProximaNova-Regular.otf) format("opentype");
}

@font-face {
  font-family: "ProximaNova Bold";
  src: local("ProximaNova Bold"),
    url(./fonts/ProximaNova-Bold.otf) format("opentype");
}

@font-face {
  font-family: "ProximaNova Black";
  src: local("ProximaNova Black"),
    url(./fonts/ProximaNova-Black.otf) format("opentype");
}

@font-face {
  font-family: "ProximaNova Light";
  src: local("ProximaNova Light"),
    url(./fonts/ProximaNova-Light.otf) format("opentype");
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: "ProximaNova Regular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  perspective: 800px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  overflow: hidden;
}
